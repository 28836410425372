<template>
    <div class="anmelden">
        <h1 class="text-center">Anmeldeformular</h1>
        <p class="lead">Alle Kinder mit Geburtsdatum vom 01.08.2016 bis 31.08.2018 erhalten eine Einladung für die Spielgruppe.</p>

        <form :class="{ 'read-only': isSubmitted }">
            <div class="flex-container flex-2">
                <div class="">
                    <div class="form-group">
                        <label for="email" class="h4">Vorame Kind</label>
                        <input type="text" id="firstname" class="form-control"
                            v-model="userData.firstname">
                    </div>
                    <div class="form-group">
                        <label for="email" class="h4">Name Kind</label>
                        <input type="text" id="name" class="form-control"
                            v-model="userData.name">
                    </div>
                    <div class="form-group">
                        <label for="email" class="h4">Adresse</label>
                        <input type="text" id="address" class="form-control"
                            v-model="userData.address">
                    </div>
                    <div class="form-group">
                        <label for="email" class="h4">PLZ/Ort</label>
                        <div class="flex-container flex-2">
                            <div class="flex-basis-25"><input type="text" id="plz" class="form-control"
                                v-model="userData.plz"></div>
                            <div class="flex-basis-75"><input type="text" id="ort" class="form-control"
                                v-model="userData.ort"></div>
                        </div>
                    </div>
                    <div class="form-group margin-bottom-25">
                        <label class="h4">Wo?</label>
                        <label for="male">
                            <input type="radio" id="st-martin" value="st-martin"
                                 v-model="radioBoxOption"> Pfarreizentrum St. Martin
                        </label>
                        <label for="female">
                            <input type="radio" id="zentral" value="zentral"
                                 v-model="radioBoxOption"> Schulhaus Zentral
                        </label>
                    </div>
                </div>

                <div class="">
                    <div class="form-group">
                        <label for="email" class="h4">Email-Adresse</label>
                        <input type="text" id="email" class="form-control"
                            v-model="userData.email">
                    </div>
                    <div class="form-group">
                        <label for="email" class="h4">Telefonnummer</label>
                        <input type="text" id="phone" class="form-control"
                            v-model="userData.phone">
                    </div>
                    <div class="form-group">
                        <label for="message" class="h4">Haben Sie Fragen?</label>
                        <textarea id="message" rows="5" class="form-control" v-model="message"></textarea>
                    </div>
                </div>
            </div>

            <div class="card margin-top-25 margin-bottom-50" v-if="isSubmitted">
                <p class="lead text-center">Besten Dank!<br>Wir melden uns so schnell wie möglich bei Ihnen.</p>
            </div>

            <button class="btn btn-primary" @click.prevent="submitted">Absenden</button>
        </form>

    </div>
</template>

<script>
// Regular expression from W3C HTML5.2 input specification:
// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail
const querystring = require("querystring");
import axios from 'axios'

export default {
    name: 'Anmelden',
    data: function() {
        return {
            userData: {
                firstname: '',
                name: '',
                address: '',
                plz: '',
                ort: '',
                email: '',
                phone: ''
            },
            message: 'Liebes Gigampfi Team... ',
            radioBoxOption: 'st-martin',
            isSubmitted: false
        };
    },
    methods: {
        submitted() {
            //this.isSubmitted = true;
            //var host = window.location.origin;
            //e.preventDefault();
            //this.$axios
            console.log("now");
            axios
                .post(
                    //process.env.ROOT_API + "/mail.php",
                    "http://spielgruppehochdorf.ch/mail.php",
                    //"http://localhost/spielgruppe-hochdorf/mail.php",
                    querystring.stringify(this.form)
                )
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                    //this.sent = true;
                    console.log(res);
                    this.isSubmitted = true;
                })
                .catch(
                    error => console.log(error)
                );
            /*return fetch('http://localhost/spielgruppe-hochdorf/mail.php', {
                method: 'post',
                body: querystring.stringify(this.form)
            })
            .then((res) => res.json())
            .then((data) => {
                return data;
            })
            .catch((err) => console.log(err))*/
        }/*,
        onSubmit(e) {
            var host = window.location.origin;
            e.preventDefault();
            this.$axios
                .post(
                    host + "/mail.php",
                    querystring.stringify(this.form)
                )
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                    //this.sent = true;
                    this.isSubmitted = true;
                });
        }*/
    },
    watch: {
        // watching nested property
        "email.value": function(value) {
            this.validate("email", value);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    form{
        max-width: 800px;
        margin: 50px auto 25px auto;

        button{
            float: right;
        }
    }
</style>
